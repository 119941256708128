.image-container {
    position:relative;
    display: inline-block;

  }
  
  .text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    color: white;
    padding: 10px;
    align-items: center;
  }