.footer {
    background-color: #1c3464;
    padding: 20px;
    position: absolute;
    width: 100%;
    z-index: 100;
    align-items: center;
    display: flex;
    
  }
  
  .footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    align-items: center;
  }
  
  .footer li {
    display: inline;
    margin: 10px 10px;

  }
  
  .footer a {

    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .footer a:hover {
    color: #aeaeae;
  }
.footertext{
    text-decoration: none;
    color: rgb(255, 255, 255);
    align-items: center;
    text-align: center;
    padding-left: 100px;
    font-family: "Manrope", sans-serif;
}
.footertextmob{
  text-decoration: none;
  color: rgb(255, 255, 255);
  align-items: center;
  text-align: center;
  font-family: "Manrope", sans-serif;
}
.footeralign{
    padding-left: 300px;
}
.gap{
  display: flex;
  flex-direction: row;
  gap: 40px;

}