.contactgap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 50px;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.contactuslogo{
    height: 90px;
    width: 180px;
}
.contactgapmob{

    align-items: center;
    gap: 10px;
    padding: 50px 50px 0px 50px;

}
.background7{
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    justify-content: space-between;
    padding: 25px;
}

.contactbgclr{
    background-color: #f1f5fe;
    border-radius: 20px;
    padding: 25px;
}

.contacttext1{
    font-size: 20px;
    color: #1c3464;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
}

.contacttext2{
    font-size: 25px;
    color: #1c3464;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
}

.contacttext3{
    font-size: 14px;
    color: #1c3464;
    font-family: "Manrope", sans-serif;
}
.aligncenter{
    display: flex;
    justify-content: center;
  }