@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap" rel="stylesheet">');

.home {

    background-image: url("../../Assets/banner03.jpg");
    background-size: cover;
    width: 100%;
    height: 700px;
    top: 0px;
    
    }
.homemob {

    background-image: url("../../Assets/bannermob.png");
    background-size: cover;
    width: 100%;
    height: 650px;
    top: 0px;
    
    }
  
.para {

    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 40px;
    padding-top: 400px;
    font-family: "Manrope", sans-serif;
    line-height: normal;
}

.paramob {
    padding-top: 400px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 25px;
    font-family: "Manrope", sans-serif;
    line-height: normal;
}

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
    border: none;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.whatsapp-button img {
    width: 40px;
    height: 40px;
}

.head {

    font-weight: bold;
    color: rgb(255, 225, 0);
}

.bn {
    background-color: rgb(255, 225, 0);
    border: 2px;
    border-color: #ffffff;
    border-radius: 5px;
    color: black;
    padding: 7px 20px;
    text-align: center;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    width: 150px;
    height: 40px;
}

