.bn5 {
    background-color: #1c3464;
    border: 2px;
    border-color: #ffffff;
    border-radius: 5px;
    color: white;
    padding: 7px 20px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    font-size: 10px;
    margin: 4px 2px;
    cursor: pointer;
    width: 200px;
    height: 40px;
  }
.grid-container {
display: flex;
flex-wrap: wrap;
align-items: flex-end;
justify-content: center;
padding: 10px 40px;
gap: 5px;
}

.grid-item {
position: relative; /* Ensure positioning context for absolute positioning */
overflow: hidden; /* Hide overflow content */
text-align: center;
align-items: flex-end;
}

.grid-item img {
width: 100%; /* Ensure the image fills its grid item */
height:auto; /* Ensure the image fills its grid item */

}

.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Align items horizontally in the center */
  }
.imagepng{
    width: 100%;
}
.columnbg{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-direction: row;
    align-items: center; /* Align items vertically in the center */
    gap: 25px; /* Adjust the gap between products */
}
.columnbgmob{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    padding: 5px 50px;
}
.bgcolour{
    background-color: #1c3063;
    padding: 0px 5px 0px 5px;
}
.productsbgclr{

    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 50px 100px;
}
.productsbgclrmob{

    display: flex;
    flex-direction: row;
    gap: 10px;
}

.background6{
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 25px;
}

.productimg{
    width: 50px;
    height: 50px;
    padding-bottom: 10px;
}
.head3{
    font-size: 15px;
    font-family: "Manrope", sans-serif;
}
.head1{
    font-size: 18px;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
}

.head1mob{
    font-size: 16px;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headcenterblack{
    padding-top: 40px;
    text-align: center;

  }

.center5{
    padding-top: 100px;
    align-items: center;
}

.productphoto2{
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}
.background8{
    background-color: rgb(255, 225, 0);
    text-align: center;
    width: 250px;
    border-radius: 8px;
}
.background8mob{
    background-color: rgb(255, 225, 0);
    text-align: center;
    width: 100%;
    border-radius: 8px;
}
.buttoncenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.headcenter2{

    justify-content: center;
    font-weight: bold;
    font-size: 30px;
    padding-top: 30px;
    text-align: center;
    color: white;
  }
  .headcenter2mob{

    justify-content: center;
    font-weight: bold;
    font-size: 22px;
    padding-top: 30px;
    text-align: center;
    color: white;
  }
.xhidden{
    overflow-x: hidden;
}
.producthead1{
    font-size: 14px;
    font-weight: bold;
    font-family: "Manrope", sans-serif;
  }
  .subProducts {
    display: none;
  }
  
  /* .background8:hover .subProducts {
    display: inline;
    position: absolute;
    background-color: #1c3063;
    border: 1px solid #fedb18;
    padding: 10px;
    z-index: 999;
    width: 200px; 
    height: 150px; 
    overflow-y: auto; 
  } */
  
  /* .subProducts p {
    margin: 5px 0;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    color: #ffffff;

  } */
  