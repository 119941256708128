
.aboutbg {
  background-image: url("../../Assets/bg.png");
  background-size: cover;
  width: 100%;

  }
/* CSS for Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 6s ease-out forwards; /* Adjusted duration to 1s */
}

.animated-image {
  transition: transform 0.3s ease-in-out;
}

.animated-image:hover {
  transform: scale(1.050);
}

.padding{
  padding-bottom:135px ;
}
.photoflex{
  padding: 0px 50px;
}
.home2 {
  position: absolute;
  right: 150px;
  top: 120px;
  z-index: -10;
  }
  
.homeimg2{
  height: 400px;
  width: 400px;
}

  .para {

    padding: 100px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
  }

  .headabout {

    font-weight: bold;
    color: rgb(255, 183, 0);
    font-size: 25px;
    font-family: "DM Sans", sans-serif;
  }
  .headmob{
    font-weight: bold;
    color: rgb(255, 183, 0);
    font-size: 25px;
    font-family: "Manrope", sans-serif;
  }
  .head2 {

    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-family: "DM Sans", sans-serif;
  }
  .head2mob {
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-family: "Manrope", sans-serif;
  }
  .head4 {
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight:bold;
    font-family: "DM Sans", sans-serif;

  }
  .head03 {
    font-size: 16px;
    color: rgb(39, 39, 39);
    text-align: justify;
    font-family: EurostileBold;

  }
  .headblock{
    text-align: center;
    font-weight: bold;
    color: #1c3464;
    font-size: large;
    background-color: white;
    padding: 30px;
    position: absolute;
  }
 

.photoabout{

    height: auto;
    width: 100%;
}

.aligncenter{
  display: flex;
  justify-content: center;
}