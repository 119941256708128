/* Main gallery container */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Flexible column widths */
  gap: 2px;
  justify-items: center;
  padding: 0px;
  background-color: #1c3464;
}

.gallery-item {
  overflow: hidden;
}

.gallerytext {
  text-align: center;
  padding: 15px;
  margin: 0;
  background-color: #1c3464;
  color: white;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

/* Back button container */
.back-button-container {
  text-align: center;
  margin-top: 20px;
}

/* Fixed back button with border */
.back-button {
  background-color: #223d73;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border: 1px solid rgb(255, 225, 0);
  border-radius: 6px;
  font-size: 14px;
  position: fixed; /* Make the button fixed */
  top: 20px; /* Position it at the top */
  left: 20px; /* Add some spacing from the left */
  z-index: 1000; /* Ensure the button is above other content */
}

/* Hover effects for back button */
.back-button:hover {
  background-color: #476297;
  color: #ffffff;
}

/* Media query for mobile responsiveness */
@media (max-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust image size on small screens */
    gap: 4px; /* Increase spacing for a cleaner look on mobile */
  }

  .gallerytext {
    font-size: 18px; /* Smaller font size for mobile */
    padding: 15px;
  }

  .back-button {
    font-size: 12px; /* Smaller button size */
    padding: 4px 8px;
    top: 10px; /* Adjust the top position for mobile view */
    left: 10px; /* Adjust the left position for mobile view */
  }
}
