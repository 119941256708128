.storagebg{
    background-color: brown;

}
  

.animated-image {
  transition: transform 0.3s ease-in-out;
}

.animated-image:hover {
  transform: scale(1.050);
}

  .photo-column {
    grid-column: 1;
  }
  
  .description-column {
    grid-column: 2;
  }
  .columnbg1mob{
    background-color: #1c3464;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 5px 50px;
}
  .photo {
    width: 100%; /* Adjust size as needed */

  }

.bgyellow{
  background-color: rgb(255, 225, 0);
  text-align: left;
  margin: 0%

}
.bgwhite{
  background-color: rgb(255, 255, 255);
  text-align: left;
  margin: 0%;

}
.storagephoto{
  border-top-left-radius: 45px ;


}

.headcenter{
  padding-top: 30px;
  text-align: center;
  color: white;
}
.storagebgclrmob{
  background-color: #1c3464;
}
.storagebgclr{
  background-color: #1c3464;
  padding-top: 120px;
}
.storagebgclrmob{
  background-color: #1c3464;
  padding-top: 0px;
}
.storagepadding{
  padding-left: 50px;
  padding-top: 40px;
}
.storagephoto2{
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.head01{
  font-size: 15px;
  font-weight: bold;
  color: white;
  font-family: "Manrope", sans-serif;

}
.storagetext {
  font-family: EurostileBold;
  color: black;
  text-align: justify;
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.bgclr01{
  background-color: #1c3464;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 250px 50px 250px;
}
.headblockmob{
  text-align: center;
  font-weight: bold;
  color: #1c3464;
  font-size: large;
  background-color: white;
  padding: 30px;
}
.storagehead1{
  font-size: 12px;
  font-weight: bold;
  font-family: "Manrope", sans-serif;
}