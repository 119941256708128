.navbar {
    background-color: transparent;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    align-items: center;
    display: flex;
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    align-items: center;
  }
  
  .navbar li {
    display: inline;
    margin: 10px 10px;

  }
  
  .navbar a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .navbar a:hover {
    color: #aeaeae;
  }

  .navbarlogo{
    width: 150px;
  }
  .navbarlogomob{
    width: 100px;
  }

.icon{
  height: 50px;
  width: 50px;
}
.gap{
  display: flex;
  flex-direction: row;
  gap: 50px;

}
.navpadding{
  padding: 10px 200px;
}
.navpaddingmob{
  padding: 10px 30px;
}